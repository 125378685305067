const languages = [
  "bash",
  "c",
  "clojure",
  "cobol",
  "coffeescript",
  "cpp",
  // "dart",
  "elixir",
  // "erlang",
  "fortran",
  "f#",
  // "forth",
  "groovy",
  // "gosu",
  // "gpc",
  "haskell",
  // "intercal",
  "java",
  "javascript",
  // "julia",
  // "kotlin",
  "lua",
  // "labview",
  // "lisp",
  // "logo",
  // "matlab",
  // "nemerle",
  // "nice",
  // "node.js",
  // "nim",
  "objective-c",
  "octave",
  "ocaml",
  "pascal",
  "perl",
  "php",
  // "pico lisp",
  // "pike",
  "prolog",
  "python",
  "python 3",
  "r",
  "racket",
  "ruby",
  "rust",
  "scala",
  "shell",
  "smalltalk",
  "sql",
  "sqlite",
  "swift",
  "tcl",
  "vb",
  "vb.net",
  "visual basic",
  "whitespace",
];

export default languages;
