const extensions = [
  ".sh",
  ".c",
  ".clj",
  ".cob",
  ".coffee",
  ".cpp",
  // ".dart",
  ".ex",
  // ".erl",
  ".f90",
  ".fs",
  // ".forth",
  ".groovy",
  // ".gs",
  // ".c",
  ".hs",
  // ".i",
  ".java",
  ".js",
  // ".jl",
  // ".kt",
  ".lua",
  // ".vi",
  // ".lisp",
  // ".logo",
  // ".m",
  // ".n",
  // ".nice",
  // ".js",
  // ".nim",
  ".m",
  ".m",
  ".ml",
  ".pas",
  ".pl",
  ".php",
  // ".l",
  // ".pike",
  ".pl",
  ".py",
  ".py",
  ".r",
  ".rkt",
  ".rb",
  ".rs",
  ".scala",
  ".sh",
  ".st",
  ".sql",
  ".sqlite",
  ".swift",
  ".tcl",
  ".vb",
  ".vb",
  ".vb",
  ".ws",
];

export default extensions;
